import { Auth } from 'aws-amplify';

import { apiUtils } from '../api/api-utils';


export interface AppConfig {
    apiUrl: string;
    awsAuth: {
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
    };
}

const awsAuth: AppConfig['awsAuth'] = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_vycYX3S7V',
    userPoolWebClientId: '1htifoc5rkneo9p167b57mcbqo'
};

// Api url for dev api
const devApiUrl = 'https://encadm-api.workforce-resource.com/api';

// Api url for backend running locally
const localBackendApiUrl = 'http://localhost:5243/api';

export default function configureApp() {
    const apiUrl = devApiUrl;

    const config: AppConfig = {
        apiUrl,
        awsAuth
    };

    Auth.configure({
        ...config.awsAuth,
        mandatorySignIn: true
    });

    apiUtils.configure(config);
}
