import { mocks as dbsecretMocks } from './dbsecret-mocks';
import { mocks as organizationMocks } from './organization/organization-mocks';
import { mocks as userMocks } from './user/user-mocks';


export const mocks = [
    ...organizationMocks,
    ...userMocks,
    ...dbsecretMocks
];
