import { apiUtils } from './api-utils';


export function getUsers(): Promise<User[]> {
    return apiUtils.get('/user');
}

export function createUser(request: User): Promise<User> {
    return apiUtils.post('/user', request);
}

export function updateUser(request: User): Promise<User> {
    return apiUtils.put(`/user/${request.email}`, request);
}

export function deleteUser(email: string): Promise<User> {
    return apiUtils.delete(`/user/${email}`);
}

export function getOrganizations(): Promise<Organization[]> {
    return apiUtils.get('/organization');
}

export function createOrganization(request: Organization): Promise<Organization> {
    return apiUtils.post('/organization', request);
}

export function updateOrganization(request: Organization): Promise<Organization> {
    return apiUtils.put(`/organization/${request.id}`, request);
}

export function fetchInstances(orgId: string): Promise<Instance[]> {
    return apiUtils.get(`/organization/${orgId}/instance`);
}

export function createInstance(orgId: string, request: Instance): Promise<Instance> {
    return apiUtils.post(`/organization/${orgId}/instance`, request);
}

export function updateInstance(orgId: string, request: Instance): Promise<Instance> {
    return apiUtils.put(`/organization/${orgId}/instance/${request.id}`, request);
}

export function getDBSecret(): Promise<DBSecret> {
    return apiUtils.get('/db-secret');
}

export function updateProductConditions(request: null): Promise<void> {
    return apiUtils.put('/product', request);
}

export interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string | null;
}

export interface Organization {
    id: string;
    organizationId: string; // display name
    isActive: boolean;
    numInstances: number;
}

export interface Instance {
    id: string;
    instanceId: string; // display name
    clientSecret: string;
    clientId: string;
}

export interface DBSecret {
    username: string;
    password: string;
    engine: string;
    host: string;
    port: number;
    dbInstanceIdentifier: string;
}
