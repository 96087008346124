import { createApiUtils, getCognitoToken } from '@tsp-ui/core/utils';
import { Auth } from 'aws-amplify';


export const apiUtils = {
    ...createApiUtils({
        getAuthToken: getCognitoIdToken,
        getRequestUrl: (url, _, config) => config?.apiUrl + url
    })
};


export async function getCognitoIdToken() {
    await getCognitoToken(); // Call the default one to initiate the refresh logic

    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
}
